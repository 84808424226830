import React from 'react';
import './styles.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const LinkExpiredPage = () => {
  MySwal.fire({
    icon: 'error',
    title: 'Payment Link Expired',
    html: 'Please close this window and submit a new transaction.',
    allowOutsideClick: false,
    showConfirmButton: false,
    footer: footer(),
  });

  return <div />;
};

const footer = () => {
  return (
    <div className="dialog-payment-footer">
      Having issues with payment?
      <a href="mailto:support@pdax.ph?Subject=Request for Support: Payments&body=I consent to having PDAX collect, process and store my information to contact me about its products and services as well as other content that may be of interest to me. %0D%0AI have reviewed PDAX privacy policy: https://pdax.ph/privacy. %0D%0A">
        Reach us here.
      </a>
    </div>
  );
};

export default LinkExpiredPage;
