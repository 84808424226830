import React from 'react';
import './styles.scss';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <div>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="592px"
          height="570px"
          viewBox="0 0 3950 3800"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="layer101" fill="#00ce88" stroke="none">
            <path d="M1090 2984 c0 -4 62 -113 139 -243 76 -130 164 -280 196 -334 38 -65 65 -101 82 -108 13 -5 193 -9 407 -9 363 0 384 -1 400 -19 9 -10 60 -92 113 -182 226 -387 424 -731 470 -819 80 -151 242 -423 265 -442 18 -16 48 -18 294 -18 151 0 274 3 274 8 0 4 -10 23 -22 42 -12 19 -148 251 -303 515 -155 264 -333 566 -395 670 -62 105 -134 231 -160 280 -91 173 -195 339 -220 352 -19 10 -122 13 -411 13 l-386 0 -20 23 c-11 12 -51 73 -89 134 -50 83 -77 117 -100 128 -26 12 -81 15 -283 15 -138 0 -251 -3 -251 -6z" />
            <path d="M150 2679 c0 -10 347 -617 420 -734 53 -84 357 -613 409 -710 105 -196 225 -388 255 -406 30 -18 67 -19 844 -19 624 0 812 3 812 12 0 7 -23 53 -51 102 -50 89 -525 902 -603 1033 -64 108 -42 103 -473 103 -250 0 -380 4 -396 11 -17 8 -70 89 -172 268 -172 297 -186 318 -231 337 -43 18 -814 21 -814 3z" />
          </g>
        </svg>
      </div>
      <div className="content">
        <h1>Error 404</h1>
        <h2>We can&apos;t seem to find the page you are looking for.</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
