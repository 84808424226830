import React from 'react';
import './styles.scss';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import withReactContent from 'sweetalert2-react-content';
import { RouterNavigation, reactRouterHistory } from '../../utils/navigation';
import { EXPIRED_PAGE } from '../../utils/urls';

const MySwal = withReactContent(Swal);

const LoadingPage = () => {
  let timerInterval = 40000;

  const { state } = queryString.parse(reactRouterHistory.location.search);

  MySwal.fire({
    title: 'Hang On!',
    html: redirectPopupPage(),
    allowOutsideClick: false,
    timer: timerInterval,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
    onClose: () => {
      clearInterval(timerInterval);
      redirect(state);
    },
  }).then(result => {
    if (result.dismiss === Swal.DismissReason.timer) {
      redirect(state);
    }
  });

  setTimeout(() => {
    redirect(state);
  }, 4000);

  return <div />;
};

const redirect = (state: any) => {
  let url = '';
  try {
    url = `${atob(state)}&token=${Math.round(Math.random() * 10000000)}`;
    window.location.href = url;
  } catch (e) {
    console.log('error while parsing state', e);
    RouterNavigation.navigateTo(EXPIRED_PAGE);
  }
};

const redirectPopupPage = () => {
  return (
    <div className="loading-page">
      <div>we are redirecting you to payments page...</div>
      <div>
        <b>Note: </b>
        Do not try to refresh page or press back button. We are confirming your payment.
      </div>
    </div>
  );
};

export default LoadingPage;
