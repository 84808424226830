import React from 'react';
import './styles.scss';

const NavBarPage = () => {
  return (
    <div className="pdax-navbar">
      {/* <img src="/sitelogo.png" alt="PDAX" /> */}
    </div>
  );
};

export default NavBarPage;
