import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { reactRouterHistory } from './utils/navigation';
import { INDEX_PAGE, LOADING_PAGE, EXPIRED_PAGE } from './utils/urls';
import NotFoundPage from './components/not-found-page';
import NavBarPage from './components/navbar';
import LoadingPage from './components/loading-page';
import LinkExpiredPage from './components/link-expired-page';

const App = () => {
  return (
    <Router history={reactRouterHistory}>
      <NavBarPage />
      <Switch>
        <Redirect exact path={INDEX_PAGE} to={LOADING_PAGE} />
        <Route path={LOADING_PAGE} component={LoadingPage} />
        <Route path={EXPIRED_PAGE} component={LinkExpiredPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
